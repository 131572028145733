/* Body and General Settings */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Main Container */
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  background-color: #0d1333;
  color: #FFFFFF;
}

/* Header */
.header {
  background-color: #050B2D;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  color: #fea83c;
  font-size: 24px;
  margin: 0;
}

.nav {
  display: flex;
  gap: 20px;
}

.navLink {
  color: #fea83c;
  text-decoration: none;
  font-size: 16px;
}

/* Main Content Layout */
.mainContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch; /* Coloanele devin egale ca înălțime */
  padding: 20px 40px; /* Adaugă spațiu pe marginile ecranului */
  gap: 20px;
  max-width: 1200px; /* Opțional: Limitează lățimea maximă a conținutului */
  margin: 0 auto; /* Centrare pe ecran */
}

.leftColumn, .rightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* Info Box and Card Styling */
.infoBox, .addMetamaskBox, .sendModule {
  background-color: #1a1a2e;
  color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  flex: 1; /* Face fiecare box să ocupe spațiul disponibil vertical */
}

.infoBox h3, .addMetamaskBox h3, .sendModule h3 {
  color: #fea83c;
  font-size: 18px;
  margin-bottom: 10px;
}

.card {
  background-color: #050B2D;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 100%;
  max-width: 500px;
  word-wrap: break-word;
}

.heading {
  color: #fea83c;
  margin-bottom: 24px;
  font-size: 24px;
}

.addressBox {
  background-color: #33302A;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 16px;
  color: #fea83c;
  font-weight: bold;
  word-wrap: break-word;
}

.text {
  color: #FFFFFF;
  margin-bottom: 16px;
  word-wrap: break-word;
  white-space: normal;
}

.warning {
  color: #FF4040;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Button Styling */
.button, .faucetButton, .addMetamaskButton, .sendButton, .closeButton, .faucet-claimButton, .faucet-closeButton {
  background-color: #ff7f50;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: fit-content;
}

.button:hover, .faucetButton:hover, .addMetamaskButton:hover, .sendButton:hover, .closeButton:hover, .faucet-claimButton:hover, .faucet-closeButton:hover {
  background-color: #ff4500;
}

/* Input Styling */
.input, .sendInput {
  padding: 10px;
  border-radius: 8px;
  border: none;
  margin-bottom: 16px;
  width: 100%;
  font-size: 16px;
  box-sizing: border-box;
}

/* Footer */
.footer {
  background-color: #050B2D;
  padding: 20px;
  text-align: center;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.footerText {
  color: #FFFFFF;
  margin-bottom: 8px;
}

.footerLink {
  color: #fea83c;
  text-decoration: none;
  margin-left: 5px;
}

/* Popup Styling */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #050B2D;
  padding: 30px;
  border-radius: 12px;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  width: 400px;
}

.popup h3 {
  color: #fea83c;
  margin-bottom: 20px;
}

.popup .input {
  margin-bottom: 10px;
}

.popup .button {
  width: 100%;
}

/* Faucet Specific Styles */
.faucet-infoBox {
  background-color: #1a1a2e;
  color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  min-width: 300px;
  margin: 20px auto;
}

.faucet-infoBox h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #fea83c;
}

.faucet-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 19, 51, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.faucet-popupContent {
  background-color: #050B2D;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.faucet-popupContent h3 {
  color: #fea83c;
  margin-bottom: 20px;
}

.faucet-popupContent p {
  font-size: 18px;
  margin: 10px 0;
}

.faucet-txHash {
  word-break: break-all;
  color: #ff7f50;
}

/* Send Module Specific Styles */
.sendModule h3 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #fea83c;
}

.errorMessage {
  color: #FF4040;
  margin-top: 10px;
}

.transactionInfo {
  margin-top: 20px;
  color: #fea83c;
}

.txHash {
  word-break: break-all;
  color: #ff7f50;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .mainContent {
    flex-direction: column;
    align-items: center;
    padding: 20px; /* Reduce padding-ul pe dispozitivele mobile */
  }
  .leftColumn, .rightColumn {
    width: 100%;
  }
}
